import { SvgIcon, SvgIconProps } from "@material-ui/core";
import * as React from "react";
import theme from "../../theme/theme";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {
  selected?: boolean;
}

/**
 * Render device icon
 */
const DeviceIcon = (props: Props) => {
  const { selected } = props; 
  return (
    <SvgIcon {...props}>
      <rect fill="none" stroke={ selected ? theme.palette.secondary.main : "#696969" } x="3" y="3" width="13.83" height="18"/>
      <line stroke={ selected ? theme.palette.secondary.main : "#696969" } x1="17.83" y1="6.83" x2="22" y2="6.83"/>
      <line stroke={ selected ? theme.palette.secondary.main : "#696969" } x1="17.83" y1="11.83" x2="22" y2="11.83"/>
      <line stroke={ selected ? theme.palette.secondary.main : "#696969" } x1="17.83" y1="16.83" x2="22" y2="16.83"/>
    </SvgIcon>
  );
}
export default DeviceIcon;